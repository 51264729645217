.bounceIn {
  animation-duration: 0.75s;
  animation-name: bounceIn;
}
.loader,
.loader:after {
  border-radius: 50%;
  width: 7em;
  height: 7em;
}
.loader {
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 9px solid rgba(255,255,255,0.03);
  border-right: 9px solid rgba(255,255,255,0.03);
  border-bottom: 9px solid rgba(255,255,255,0.03);
  border-left: 9px solid #fff;
  transform: translateZ(0);
  animation: load 1.1s infinite linear !important;
}
.slideUp-enter {
  opacity: 0;
  transform: scale(0.6);
  transition: 0.32s cubic-bezier(0.82, 0, 0.12, 1) all;
}
.slideUp-enter.slideUp-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: 0.32s cubic-bezier(0.82, 0, 0.12, 1) all;
}
.slideUp-leave {
  opacity: 1;
  transition: 0.32s cubic-bezier(0.82, 0, 0.12, 1) all;
  transform: scale(1);
}
.slideUp-leave.slideUp-leave-active {
  opacity: 0;
  transform: scale(1.4);
}
.slideDown-enter {
  opacity: 0;
  transform: scale(1.4);
  transition: 0.32s cubic-bezier(0.82, 0, 0.12, 1) all;
}
.slideDown-enter.slideDown-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: 0.32s cubic-bezier(0.82, 0, 0.12, 1) all;
}
.slideDown-leave {
  opacity: 1;
  transition: 0.32s cubic-bezier(0.82, 0, 0.12, 1) all;
  transform: scale(1);
}
.slideDown-leave.slideDown-leave-active {
  opacity: 0;
  transform: scale(0.6);
}
html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
body {
  display: none;
}
.loaded {
  display: block;
}
@-moz-keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}
@-webkit-keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}
@-o-keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}
@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}
@-moz-keyframes load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-o-keyframes load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-moz-keyframes breathing {
  0% {
    transform: scale(0.94);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.94);
  }
}
@-webkit-keyframes breathing {
  0% {
    transform: scale(0.94);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.94);
  }
}
@-o-keyframes breathing {
  0% {
    transform: scale(0.94);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.94);
  }
}
@keyframes breathing {
  0% {
    transform: scale(0.94);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.94);
  }
}
body {
  background: #252e37;
  color: #f7fbff;
  font-family: 'Raleway', sans-serif;
  font-weight: 300;
  font-size: 21px;
  fill: #f7fbff;
  margin: 0;
  padding: 0;
  position: relative;
  text-shadow: 0px 0px 6px rgba(175,245,255,0.4), 0px 0.5px 0px #c0d2d1, 0px 3px 1px rgba(0,10,20,0.7);
  min-width: 320px;
}
body .bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #2b353e;
  z-index: 15;
}
body #home .section {
  position: relative;
  z-index: 20;
}
body #home .section .sectionInner {
  display: flex;
  align-items: center;
  flex-direction: column;
  transition: 0.32s cubic-bezier(0.82, 0, 0.12, 1) all;
  transform: translateZ(0);
}
body #home .logo {
  position: relative;
  margin-top: 145px;
}
body #home .logo .logoTop {
  fill: #f7fbff;
  position: absolute;
  top: -3px;
  right: 0;
  bottom: 3px;
  left: 0;
  z-index: 2;
  filter: drop-shadow(0px 0px 12px rgba(175,245,255,0.25));
}
body #home .logo .logoBot {
  position: relative;
  z-index: 1;
  fill: #c0d2d1;
  filter: drop-shadow(0px 4px 1px #0b1819);
}
body #home .titleWrap {
  border-radius: 5px;
}
body #home .titleWrap .title {
  letter-spacing: 13px;
  padding-left: 1px;
  padding-top: 80px;
  margin-left: 13px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 36px;
}
body #home .titleWrap .subtitle {
  margin-top: 10px;
  letter-spacing: 2px;
  padding-left: 2px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  font-size: 16px;
  text-align: center;
}
body #home .downloads {
  margin: 85px 0px 0px 0px;
  background: linear-gradient(-10deg, #53ffdd 0%, #80f4ff 100%);
  padding: 15px 30px;
  border-radius: 5px;
  cursor: pointer;
  color: rgba(49,62,75,0.8);
  fill: rgba(49,62,75,0.8);
  text-shadow: none;
  font-weight: 600;
  box-shadow: 0px 3px 0px #06cca3, 0px 0px 60px rgba(128,244,255,0.25), 0px 30px 30px -20px rgba(0,15,20,0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
body #home .downloads svg {
  margin-top: 9px;
  padding-right: 20px;
}
body #home .downloads .releaseText {
  text-align: center;
  width: 240px;
}
body #home .downloads .releaseVersion {
  font-size: 14px;
  text-align: center;
}
body #home .extensions {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 65px;
  font-size: 12px;
  font-weight: 400;
  line-height: 25px;
  text-shadow: 0px 0px 6px rgba(175,245,255,0.4), 0px 2px 1px rgba(0,10,20,0.8);
  text-transform: uppercase;
  letter-spacing: 2px;
}
body #home .extensions .extIcons {
  display: flex;
}
body #home .extensions .extIcons a,
body #home .extensions .extIcons a:active,
body #home .extensions .extIcons a:visited {
  color: inherit;
  text-decoration: none;
}
body #home .extensions .extIcons .extIcon {
  padding: 0px 10px 20px 10px;
}
body #home .devNotice {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  text-shadow: 0px 0px 6px rgba(175,245,255,0.4), 0px 2px 1px rgba(0,10,20,0.8);
}
body #home .devNotice .devNoticeAlso {
  font-size: 12px;
}
body #home .devNotice .devNoticeSmall {
  font-size: 12px;
  margin-top: 2px;
  line-height: 12px;
}
body #home .devNotice .signUpBelow {
  margin-top: 20px;
}
body #home .devNotice a {
  text-decoration: none;
  margin-top: 7px;
  color: #f7fbff;
  fill: #f7fbff;
  position: relative;
  padding-right: 24px;
}
body #home .devNotice a span {
  position: absolute;
  top: 0;
  right: 0px;
  bottom: 1px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  filter: drop-shadow(0px 3px 1px rgba(5,25,25,0.5));
  pointer-events: none;
}
body #home .highlight {
  color: #53ffdd;
  text-shadow: 0px 0px 6px rgba(175,245,255,0.4), 0px 0.5px 0px #06cca3, 0px 3px 1px rgba(0,10,20,0.7);
}
body #home .highlightSmall {
  color: #53ffdd;
  text-shadow: 0px 0px 6px rgba(175,245,255,0.4), 0px 0.5px 0px #06cca3, 0px 2px 1px rgba(0,10,20,0.8);
}
body #home .description {
  text-align: center;
  line-height: 30px;
  margin-top: 75px;
  width: 875px;
  box-sizing: border-box;
  padding: 0px 60px;
}
body #home .quote {
  text-align: center;
  line-height: 30px;
  margin-top: 85px;
  width: 875px;
  box-sizing: border-box;
  padding: 0px 60px;
}
body #home .name {
  text-align: center;
  line-height: 30px;
  margin-top: 10px;
  width: 875px;
  box-sizing: border-box;
  padding: 0px 60px;
  font-size: 16px;
}
body #home .features {
  height: 160px;
  width: 875px;
  background: #252e37;
  display: flex;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-top: 80px;
  box-sizing: border-box;
  padding: 0px 20px;
}
body #home .features .feature {
  width: 275px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 18px;
}
body #home .features .feature svg {
  margin-bottom: 15px;
  filter: drop-shadow(0px 3px 1px rgba(5,25,25,0.5));
}
body #home .demo {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
body #home .demo .demoContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  width: 875px;
  background: #f7fbff;
  color: #212d2e;
  border-radius: 5px;
  margin-top: -10px;
  z-index: 10px;
  box-shadow: 0px 3px 0px #c0d2d1, 0px 0px 60px rgba(128,244,255,0.2), 0px 43px 30px -26px rgba(0,10,20,0.5);
}
body #home .demo .demoContainer .demoContainerInset {
  height: 480px;
  width: 855px;
  overflow: hidden;
  border-radius: 3px;
}
body #home .demo .demoContainer .demoContainerInset video {
  width: 855px;
}
body #home .demo .demoSliceWrap {
  position: absolute;
  float: center;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 9px;
  overflow: hidden;
}
body #home .demo .demoSliceWrap .demoSlice {
  position: absolute;
  float: center;
  top: 80px;
  left: -200px;
  right: -200px;
  bottom: 80px;
  background: linear-gradient(174deg, #53ffdd 0%, #80f4ff 100%);
  transform: rotate(3deg);
  z-index: 9px;
}
body #home .email {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  line-height: 30px;
  padding-top: 55px;
}
body #home .email .emailWrap {
  margin: 45px 0px 45px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 60px;
}
body #home .email .emailWrap .emailMessage {
  font-size: 30px;
  font-weight: 500;
  color: #53ffdd;
  text-shadow: 0px 0px 15px rgba(175,245,255,0.4), 0px 3px 2px rgba(0,10,20,0.8);
}
body #home .email .emailWrap .invalid {
  color: #fa649b;
  text-shadow: 0px 0px 15px rgba(255,145,155,0.3), 0px 3px 2px rgba(60,10,20,0.8);
}
body #home .email .emailWrap .emailInput {
  position: relative;
  width: 300px;
  height: 60px;
  margin-right: 15px;
  border-radius: 5px;
  background: #f7fbff;
  box-shadow: 0px 3px 0px #c0d2d1, 0px 0px 60px rgba(128,244,255,0.2), 0px 13px 15px -7px rgba(0,15,20,0.6);
}
body #home .email .emailWrap input {
  border: none;
  outline: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0px 20px;
  background: transparent;
  font-size: 21px;
  color: rgba(49,62,75,0.8);
  text-align: center;
}
body #home .email .emailWrap .emailSubmit {
  background: linear-gradient(-10deg, #53ffdd 0%, #80f4ff 100%);
  width: 140px;
  height: 60px;
  border-radius: 5px;
  box-shadow: 0px 3px 0px #06cca3, 0px 0px 60px rgba(128,244,255,0.2), 0px 13px 15px -7px rgba(0,15,20,0.6);
  color: rgba(49,62,75,0.8);
  fill: rgba(49,62,75,0.8);
  text-shadow: none;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
body #home .supporters {
  padding-top: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}
body #home .supporters .supportersText {
  margin-top: 20px;
  font-size: 16px;
}
body #home .supporters img {
  position: relative;
  top: 0px;
  filter: hue-rotate(-11deg) drop-shadow(0px 3px 2px rgba(5,15,25,0.6));
}
body .social {
  padding-top: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
body .social svg {
  fill: #f7fbff;
  filter: drop-shadow(0px 0px 12px rgba(175,245,255,0.25));
}
body .needHelp {
  padding-top: 170px;
  padding-bottom: 170px;
  text-align: center;
  font-size: 14px;
  color: #f7fbff;
}
body .needHelp a {
  color: #53ffdd;
  text-decoration: none;
  transition: 0.64s cubic-bezier(0.82, 0, 0.12, 1) all;
  text-shadow: 0px 0px 6px rgba(175,245,255,0.4), 0px 0.5px 0px #06cca3, 0px 3px 1px rgba(0,10,20,0.7);
}
@media (max-width: 920px) {
  body #home .description,
  body #home .quote {
    width: 675px;
    padding: 0px 40px;
  }
  body #home .features {
    width: 675px;
  }
  body #home .features .feature {
    width: 225px;
  }
  body #home .demo .demoContainer {
    height: 385px;
    width: 675px;
  }
  body #home .demo .demoContainer .demoContainerInset {
    height: 365px;
    width: 655px;
  }
  body #home .demo .demoContainer .demoContainerInset video {
    width: 655px;
  }
  body #home .demo .demoSliceWrap .demoSlice {
    top: 60px;
    bottom: 60px;
  }
}
@media (max-width: 720px) {
  body #home .description,
  body #home .quote {
    width: 475px;
    padding: 0px 30px;
  }
  body #home .features {
    width: 475px;
    height: 100px;
  }
  body #home .features .feature {
    width: 158px;
    font-size: 13px;
    height: 90px;
  }
  body #home .features .feature svg {
    height: 24px;
    margin-bottom: 7px;
  }
  body #home .demo .demoContainer {
    height: 271px;
    width: 475px;
  }
  body #home .demo .demoContainer .demoContainerInset {
    height: 251px;
    width: 455px;
  }
  body #home .demo .demoContainer .demoContainerInset video {
    width: 455px;
  }
  body #home .demo .demoSliceWrap .demoSlice {
    top: 40px;
    bottom: 40px;
  }
  body #home .email {
    width: 375px;
  }
  body #home .email .emailWrap {
    height: 140px;
    flex-direction: column;
  }
  body #home .email .emailWrap .emailInput {
    position: relative;
    width: 335px;
    margin-right: 0px;
  }
  body #home .email .emailWrap .emailSubmit {
    margin: 20px 0px 0px 0px;
    width: 375px;
    height: 60px;
  }
}
@media (max-width: 520px) {
  body #home .titleWrap .subtitle {
    font-size: 13px;
    font-weight: 400;
  }
  body #home .downloads {
    padding: 10px 25px;
  }
  body #home .downloads svg {
    margin-top: 9px;
    padding-right: 20px;
    height: 30px;
  }
  body #home .downloads .releaseText {
    width: 190px;
    font-size: 18px;
  }
  body #home .downloads .releaseVersion {
    font-size: 12px;
  }
  body #home .description,
  body #home .quote {
    width: 300px;
    font-size: 18px;
    padding: 0px 10px;
  }
  body #home .devNotice {
    padding: 0px 20px;
    text-align: center;
  }
  body #home .devNotice .devNoticeAlso {
    display: none;
  }
  body #home .devNotice .devNoticeSmall {
    font-size: 10px;
  }
  body #home .devNotice .signUpBelow {
    font-size: 11px;
  }
  body #home .features {
    width: 320px;
    height: 100px;
  }
  body #home .features .feature {
    width: 100px;
    font-size: 10px;
    height: 90px;
  }
  body #home .features .feature svg {
    height: 24px;
    margin-bottom: 7px;
  }
  body #home .email {
    width: 300px;
    padding: 85px 0px 0px 0px;
  }
  body #home .email .emailWrap {
    height: 140px;
    flex-direction: column;
  }
  body #home .email .emailWrap .emailInput {
    position: relative;
    width: 260px;
    margin-right: 0px;
  }
  body #home .email .emailWrap .emailSubmit {
    margin: 20px 0px 0px 0px;
    width: 300px;
    height: 60px;
  }
  body #home .demo .demoContainer {
    height: 182px;
    width: 320px;
  }
  body #home .demo .demoContainer .demoContainerInset {
    height: 172px;
    width: 300px;
  }
  body #home .demo .demoContainer .demoContainerInset video {
    width: 300px;
  }
  body #home .demo .demoSliceWrap .demoSlice {
    top: 20px;
    bottom: 20px;
  }
}
.header {
  position: fixed;
  top: calc(-100% + 50px);
  left: 0;
  right: 0;
  height: 100%;
  z-index: 30;
  transition: 0.64s cubic-bezier(0.82, 0, 0.12, 1) all;
  transform: translateZ(0);
  box-shadow: 0px 1px 0px rgba(0,15,20,0.1);
  min-width: 320px;
  background: rgba(44,55,65,0.98);
}
.header .statusBar {
  height: 50px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 16px;
  cursor: pointer;
  transform: translateZ(0);
}
.header .statusBar .statusIndicator {
  position: absolute;
  top: 0;
  left: 20px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header .statusBar .statusIndicator .statusIndicatorBadge {
  background: #fa649b;
  height: 12px;
  width: 12px;
  border-radius: 6px;
  margin-right: 18px;
  margin-top: 1px;
  box-shadow: 0px 0px 6px #fa649b, 0px 3px 1px rgba(0,10,20,0.7);
}
.header .statusBar .statusIndicator .statusIndicatorBadgeConnected {
  background: #53ffdd;
  box-shadow: 0px 0px 6px #53ffdd, 0px 3px 1px rgba(0,10,20,0.7);
}
.header .statusBar .statusIndicator .statusIndicatorText {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  margin-top: 1px;
  letter-spacing: 1px;
}
.header .statusBar .toggleHeader {
  position: absolute;
  top: 0;
  right: 4px;
  width: 45px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.64s cubic-bezier(0.82, 0, 0.12, 1) all;
  transform: translateZ(0);
  transform-style: preserve-3d;
  margin-top: 6px;
  filter: drop-shadow(0px 2px 0px rgba(0,15,20,0.7));
}
.header .statusBar .toggleHeaderOpened {
  margin-top: -6px;
  transform: scaleY(-1) translateZ(0);
}
.header .dropTop {
  position: absolute;
  left: 16px;
  right: 16px;
  top: 0;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #f7fbff;
  justify-content: space-between;
}
.header .dropTop .extLink {
  display: flex;
  justify-content: center;
  align-items: center;
}
.header .dropTop .extLink .extIcon {
  padding-left: 14px;
}
.header .dropTop a {
  font-size: 16px;
  color: #53ffdd;
  text-decoration: none;
  transition: 0.64s cubic-bezier(0.82, 0, 0.12, 1) all;
  text-shadow: 0px 0px 6px rgba(175,245,255,0.4), 0px 0.5px 0px #06cca3, 0px 3px 1px rgba(0,10,20,0.7);
}
.header .extension {
  position: absolute;
  top: 0px;
  right: 20px;
  font-size: 16px;
  color: #53ffdd;
  text-decoration: none;
}
.header .dropMain {
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
  top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transform: translateZ(0);
}
.headerExapnd {
  top: 0;
}
#dapp {
  height: 100%;
  width: 100%;
  min-width: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  color: #f7fbff;
}
#dapp #slide {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}
#dapp #slide .defaultTitle {
  padding-bottom: 20px;
  padding-left: 0px;
  font-size: 40px;
  font-weight: 300;
}
#dapp #slide .defaultText {
  padding: 0px;
  padding-bottom: 30px;
}
#dapp #slide .titleSlide {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
#dapp #slide .logoTitle {
  font-size: 34px;
  padding: 50px 40px 30px 65px;
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: 25px;
  font-family: helvetica;
}
#dapp #slide .connectionStatus {
  height: 150px;
  width: 120px;
  position: relative;
}
#dapp #slide .connectionStatus .connectionStatusInner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
#dapp #slide .connectionStatus .connectionStatusInner .check {
  height: 100px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: rgba(0,255,255,0.05);
  fill: #f7fbff;
}
#dapp #slide .connectionStatusText {
  text-align: center;
  padding: 0px 30px;
  font-size: 18px;
}
#dapp #slideIndex {
  position: absolute;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  left: 10px;
  top: 13px;
  font-weight: 300;
  font-size: 20px;
}
#dapp .accountNotice {
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 30px;
}
#dapp .accountNotice .accountNoticeTitle {
  min-height: 100px;
  min-width: 100px;
  border-radius: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-weight: 400;
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-left: 1px;
}
#dapp .accountNotice .noticeStatusText {
  font-weight: 300;
  padding-top: 23px;
  padding-bottom: 34px;
  height: 20px;
}
#dapp .accountNotice .noticeStatusInner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#dapp .accountNotice .noticeStatusInner .check {
  height: 100px;
  width: 100px;
  border-radius: 50px;
  background: rgba(0,255,255,0.05);
  fill: #f7fbff;
  padding: 0;
}
#dapp .accountNotice .noticeStatusInner .check .checkIcon {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#dapp .loader {
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 9px solid rgba(0,255,255,0.05);
  border-right: 9px solid rgba(0,255,255,0.05);
  border-bottom: 9px solid rgba(0,255,255,0.05);
  border-left: 9px solid #53ffdd;
  transform: translateZ(0);
  animation: load 1.1s infinite linear !important;
}
#dapp .accountMain {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#dapp .accountMain .accountSubtitle {
  font-weight: 500;
  font-size: 13px;
  padding-top: 10px;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding-left: 2px;
  color: #53ffdd;
  text-shadow: 0px 0px 6px rgba(175,245,255,0.4), 0px 0.5px 0px #06cca3, 0px 3px 1px rgba(0,10,20,0.7);
}
#dapp .accountMain .accountAddress {
  padding-top: 20px;
  font-weight: 100;
  font-size: 24px;
}
#dapp .accountMain .accountBalance {
  padding-top: 20px;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#dapp .buttonWrap {
  margin-top: 30px;
  cursor: pointer;
}
#dapp .buttonWrap .button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 6px;
  background: linear-gradient(-10deg, #53ffdd 0%, #80f4ff 100%);
  padding: 20px 30px 17px 30px;
  border-radius: 5px;
  cursor: pointer;
  color: rgba(49,62,75,0.8);
  fill: rgba(49,62,75,0.8);
  text-shadow: none;
  font-weight: 600;
  box-shadow: 0px 3px 0px #06cca3, 0px 0px 60px rgba(128,244,255,0.25), 0px 30px 30px -20px rgba(0,15,20,0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-size: 16px;
  letter-spacing: 2px;
  text-transform: uppercase;
}
#dapp .buttonWrapDisabled .button {
  background: rgba(0,255,255,0.05);
  box-shadow: none;
  color: #fff;
  padding: 15px 30px 15px 30px;
  cursor: default;
  height: 30px;
}
.num {
  font-family: 'Dosis', sans-serif;
}
.networkId {
  text-align: center;
}
.extrasWrap {
  display: flex;
  align-items: center;
  flex-direction: column;
  opacity: 1;
  height: 200px;
  transition: 0.16s cubic-bezier(0.82, 0, 0.12, 1) all;
}
.extrasWrapHidden {
  opacity: 0;
}
.launchGuide {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #f7fbff;
  border-bottom: 1px solid #f7fbff;
  margin-top: 40px;
  width: 100px;
  padding: 0px 0px 9px 0px;
  filter: drop-shadow(0px 2px 0px rgba(0,15,20,0.7));
  transform: translateZ(0);
}
#dapp #slide .slideWrap .dropSide {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media (max-width: 720px) {
  body #dapp .accountNotice {
    font-size: 26px;
  }
}
@media (max-width: 590px) {
  body .header .dropTop {
    justify-content: center;
    left: 5px;
    right: 5px;
  }
  body .header .dropTop font-size 10px a {
    font-size: 10px;
  }
  body .header .dropTop .extLink {
    display: none;
  }
}
@media (max-width: 520px) {
  body #dapp {
    font-size: 15px;
  }
  body #dapp .accountNotice {
    font-size: 17px;
  }
  body #dapp .accountMain .accountAddress {
    font-size: 15px;
  }
  body #dapp .buttonWrap .button {
    padding: 20px 15px 17px 15px;
    font-size: 12px;
    letter-spacing: 1px;
  }
  body #dapp .buttonWrapDisabled .button {
    font-size: 10px;
    padding: 20px 15px 17px 15px;
  }
}
@media (max-height: 570px) {
  body #dapp {
    font-size: 15px;
  }
  body #dapp .accountNotice {
    font-size: 17px;
  }
  body #dapp .accountNotice .noticeStatusText {
    padding-top: 13px;
    padding-bottom: 14px;
  }
  body #dapp .accountMain {
    display: none;
  }
}
